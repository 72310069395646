import React from "react";
import PropTypes from "prop-types";

const TagList: React.FC<TagListProps> = ({ tags }) =>
  Array.isArray(tags) ? (
    <ul className="flex justify-start flex-wrap space-x-1 text-gray-600 dark:text-gray-400">
      {tags.map((t) => (
        <li key={t}>#{t}</li>
      ))}
    </ul>
  ) : null;

TagList.displayName = "TagList";

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default TagList;

export type TagListProps = {
  tags?: string[];
};
