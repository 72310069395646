import React from "react";
import PropTypes from "prop-types";
import { graphql, PageProps } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import "katex/dist/katex.min.css";
import TagList from "../components/TagList";
import { ChildMarkdownRemark, PostFrontmatter, QueryFragment } from "../models";
import MarkdownContent from "../components/MarkdownContent";

const PostTemplate: React.FC<PageProps<BlogPostQueryResult>> = ({ data }) => {
  const { markdownRemark: post, site } = data;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <Helmet title={`${site.siteMetadata.title} - ${post.frontmatter.title}`}>
        <html lang={post.frontmatter.language} />
      </Helmet>
      <header lang={post.frontmatter.language}>
        <div className="my-4 text-gray-800 dark:text-gray-300">
          <time>{post.frontmatter.date}</time>
          <TagList tags={post.frontmatter.tags} />
        </div>
        <h1 className="font-light text-3xl mt-4 mb-8 dark:text-gray-300">
          {post.frontmatter.title}
        </h1>
      </header>
      <MarkdownContent html={post.html} lang={post.frontmatter.language} />
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
      html: PropTypes.string.isRequired,
    }),
    site: PropTypes.object.isRequired,
  }) as PropTypes.Validator<BlogPostQueryResult>,
};

export default PostTemplate;

type BlogPostQueryResult = QueryFragment<
  "markdownRemark",
  ChildMarkdownRemark<PostFrontmatter>
> &
  QueryFragment<"site", { siteMetadata: { title: string } }>;

export const query = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        language
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
